import React from 'react'
import {text} from './text.module.scss'

interface Props {
  children: React.ReactNode
  color: string
  size: string
}

const Text: React.FC<Props> = ({children, color, size}) => {
  const style = {
    color,
    fontSize: size,
  }
  return (
    <p className={text} style={style}>
      {children}
    </p>
  )
}

export default Text
