import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import {Certificate} from './Footer'

const Contacts: React.FC<Certificate> = ({jobCode, dateOfPrep}) => {
  return (
    <Row className="pb-4 mt-lg-5 align-items-center footer-contacts">
      <Col lg="3">
        <StaticImage
          className="my-5 m-lg-0"
          alt="MSD Logo"
          src={'../../assets/images/MSD-logo-white.svg'}
          width={150}
          formats={['auto', 'webp', 'avif']}
        />
      </Col>
      <Col lg="4">
        <div>
          <span className="job-bag-no">{jobCode}</span>{' '}
          <span className="date-of-prep">{dateOfPrep}</span>
        </div>
        <div>Merck Sharp &amp; Dohme (UK) Limited</div>
        <div>Registered Office: 120 Moorgate, London,</div>
        <div>EC2M 6UR, United Kingdom</div>
      </Col>
      <Col lg="5">
        <div>Registered in England No. 233687</div>
        <div>
          Copyright © 2023 Merck & Co., Inc., Rahway, NJ, USA and its
          affiliates.
        </div>
        <div>All rights reserved.</div>
      </Col>
    </Row>
  )
}

export default Contacts
