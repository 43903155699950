import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {link} from './Links.module.scss'

const Links: React.FC = () => {
  return (
    <Row className="footer-links">
      <Col>
        <Row>
          <Col xs="5">
            <a
              className={link}
              target="_blank"
              rel="noreferrer"
              href="https://www.msd-uk.com/contact-us/"
            >
              Contact us
            </a>
          </Col>
          <Col xs="2" className="d-flex justify-content-center d-lg-none">
            <span>|</span>
          </Col>
          <Col xs="5" className="d-flex justify-content-end">
            <a
              className={link}
              target="_blank"
              rel="noreferrer"
              href="http://www.msdproduct.co.uk/terms_of_use/TermsofUse.pdf"
            >
              Terms of use
            </a>
          </Col>
        </Row>
        <Row>
          <Col xs="5">
            <a
              className={link}
              target="_blank"
              rel="noreferrer"
              href="https://www.msdprivacy.com/uk/en/"
            >
              Privacy policy
            </a>
          </Col>
          <Col xs="2" className="d-flex justify-content-center d-lg-none">
            <span>|</span>
          </Col>
          <Col xs="5" className="d-flex justify-content-end">
            <a
              className={link}
              target="_blank"
              rel="noreferrer"
              href="https://www.msd-uk.com/company-overview/"
            >
              About MSD
            </a>
          </Col>
          {/* 
            The below is a button as it doesn't go anywhere and this prevents URL changes
          */}
          <Col>
            {' '}
            <button className={`ot-sdk-show-settings btn`}>
              Cookie Settings
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Links
