import React from 'react'
import {Row, Col, Image} from 'react-bootstrap'

import {
  icon,
  bodyIcon,
  list as styleList,
} from './SeriousInfections.module.scss'

import SeriousConditions from '../../assets/images/icons/serious-conditions.inline.svg'

interface ListItem {
  img: string
  text: string
  alt: string
}
interface Props {
  list: ListItem[]
}

const SeriousInfections = ({list}: Props) => {
  return (
    <Row className="justify-content-center pb-4">
      <Col
        lg={6}
        className="d-flex justify-content-center align-items-center pb-5 pb-lg-0"
      >
        <SeriousConditions className={bodyIcon} />
      </Col>
      <Col
        lg={6}
        className="d-flex flex-column justify-content-center align-items-center gap-4 px-5 pt-5 pt-lg-0"
      >
        <ul className={styleList}>
          {list.map(({img, text, alt}) => (
            <li key={text} className="d-flex align-items-center gap-4">
              <Image src={img} alt={alt} className={icon} />
              <span>{text}</span>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  )
}

export default SeriousInfections
