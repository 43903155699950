import {Age, Disease, EligibilityOption, LongTermIssues} from './types'

export const isEligible = (
  age: Age,
  issues: LongTermIssues,
  disease: Disease,
) =>
  !!(
    age === Age.Over65 ||
    (age === Age.YoungerThen65 &&
      issues === LongTermIssues.Yes &&
      disease !== 'others')
  )

export const showEligibleCard = (
  age: Age,
  issues: LongTermIssues,
  option?: EligibilityOption,
) =>
  !!(
    age === Age.Over65 ||
    (age && issues === LongTermIssues.No) ||
    (age && issues && option)
  )
