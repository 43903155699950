import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import ExternalLinkPopUp from '../ExternalLinkPopUp'

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const SideEffects: React.FC = () => {
  const [showExternalLinkPopUp, setShowExternalLinkPopUp] = useState(false)

  return (
    <Row className="py-4">
      <Col>
        <div className="footer-side-effects text-break">
          <span className="footer-side-effects-title">
            Reporting of side effects
          </span>
          <p>
            If you get any side effects with any medicines, talk to your doctor,
            pharmacist or nurse. This includes any possible side effects not
            listed in the medicines package leaflet. You can also report side
            effects directly via the Yellow Card Scheme at{' '}
            <a
              className="external"
              target="_blank"
              rel="noreferrer"
              onClick={() => setShowExternalLinkPopUp(true)}
            >
              yellowcard.mhra.gov.uk
            </a>{' '}
            <ExternalLinkPopUp
              show={showExternalLinkPopUp}
              link={'https://yellowcard.mhra.gov.uk'}
              handleClose={() => setShowExternalLinkPopUp(false)}
            />
            or by searching for MHRA Yellow Card in the Google Play or Apple App
            Store.
          </p>
          <p>
            By reporting side effects you can help provide more information on
            the safety of medicines. By clicking the above link you will leave
            the MSD website and be taken to the MHRA website.
          </p>
        </div>
      </Col>
    </Row>
  )
}

export default SideEffects
