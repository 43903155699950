import {Link} from 'gatsby'
import React from 'react'
import {button} from './BigButton.module.scss'

interface Props {
  children: React.ReactNode
  as?: 'a'
  href?: string
  className?: string
  onClick?: () => void
  targetBlank?: boolean
}

const BigButton: React.FC<Props> = (props) => {
  const {
    children,
    as,
    className = '',
    href = '',
    onClick,
    targetBlank = false,
  } = props

  if (as === 'a') {
    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault()
      if (onClick) onClick()
    }

    return (
      <a
        className={`${className} ${button}`}
        href={href}
        target={targetBlank ? '_blank' : '_self'}
        rel="noreferrer"
        onClick={handleClick}
      >
        {children}
      </a>
    )
  }

  return (
    <Link className={`${className} ${button}`} to={href}>
      {children}
    </Link>
  )
}

export default BigButton
