export enum Age {
  Init = 0,
  YoungerThen65 = 1,
  Over65 = 2,
}

export enum LongTermIssues {
  Init = 0,
  Yes = 1,
  No = 2,
}

export type Disease = 'risks' | 'diabetes' | 'copd' | 'others' | undefined

export interface EligibilityOption {
  label: string
  value: string
  disease: Disease
}
