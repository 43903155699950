import React, {useState} from 'react'
import {Button} from 'react-bootstrap'

import {radioButton} from './FeedbackForm.module.scss'
import {getFormProperties, pushFormEventToGA} from './feedback-utils'

enum Response {
  Yes = 'Yes',
  No = 'No',
  Undecided = "I'm undecided",
}

interface Props {
  setFeedbackSumbitted: (status: boolean) => void
  formName?: string
}

const FeedbackForm = ({formName, setFeedbackSumbitted}: Props) => {
  const [feedbackResponse, setFeedbackResponse] = useState<Response>()

  const {eventCategory, surveyStatusCookieName} = getFormProperties(formName)

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    pushFormEventToGA({
      cookieName: surveyStatusCookieName,
      cookieValue: 'survey-submitted',
      eventLabel: feedbackResponse || '',
      eventCategory,
    })

    setFeedbackSumbitted(true)
  }

  const RadioButton = ({name, value}: {name: string; value: Response}) => (
    <div className={radioButton} key={name}>
      <input
        type="radio"
        id={name}
        name="feedback"
        value={value}
        onChange={() => setFeedbackResponse(value)}
        checked={value === feedbackResponse}
      />
      <label htmlFor={name}>{value}</label>
    </div>
  )

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="d-inline-flex gap-3 flex-column justify-content-center mt-3">
        {Object.entries(Response).map(([name, value]) => (
          <RadioButton key={name} name={name} value={value} />
        ))}
      </div>

      <Button
        variant="outline-secondary"
        className={`d-block rounded-pill mx-auto mt-4${
          feedbackResponse ? '' : ' invisible'
        }`}
        style={{borderWidth: '3px', padding: '0.75rem 4rem'}}
        type="submit"
        disabled={!feedbackResponse}
      >
        Send Feedback
      </Button>
    </form>
  )
}

export default FeedbackForm
