import {getCookie, setCookie} from '../../utils'

export function getFormProperties(formName?: string) {
  const eventCategory = formName ? `survey-${formName}` : 'survey'
  const surveyStatusCookieName = `${eventCategory}-status`
  return {eventCategory, surveyStatusCookieName}
}

/** Sets a cookie to prevent the form from being displayed again
 *  and sends the event to Google Analytic */
export function pushFormEventToGA({
  cookieName,
  cookieValue,
  eventLabel,
  eventCategory,
}: {
  cookieName: string
  cookieValue: string
  eventLabel: string
  eventCategory: string
}) {
  if (getCookie(cookieName) !== null) return

  setCookie(cookieName, cookieValue)
  window.dataLayer.push({
    event: 'gaevent',
    event_action: 'click',
    event_label: eventLabel,
    event_category: eventCategory,
  })
}
