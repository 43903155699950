import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {StaticImage} from 'gatsby-plugin-image'
import FeedbackForm from './FeedbackForm'
import {getFormProperties, pushFormEventToGA} from './feedback-utils'

import {title, closeButton} from './Feedback.module.scss'

interface Props {
  show: boolean
  setShowFeedback: (show: boolean) => void
  formName?: string
}

const Feedback = ({show, setShowFeedback, formName}: Props) => {
  const [feedbackSumbitted, setFeedbackSumbitted] = useState(false)

  const {eventCategory, surveyStatusCookieName} = getFormProperties(formName)

  const handleCloseFormButton = () => {
    pushFormEventToGA({
      cookieName: surveyStatusCookieName,
      cookieValue: 'survey-shown',
      eventLabel: "I'd rather not say",
      eventCategory,
    })

    resetForm()
  }

  const resetForm = () => {
    setFeedbackSumbitted(false)
    setShowFeedback(false)
  }

  return (
    <Modal
      show={show}
      onHide={resetForm}
      centered
      size="lg"
      restoreFocus={false}
    >
      <Modal.Body
        className="border border-4 rounded border-secondary p-2 pb-4 text-secondary text-center"
        style={{backgroundColor: '#f7f7f7'}}
      >
        <StaticImage
          src="./img/logo-msd-color.png"
          alt="MSD logo"
          width={80}
          className="position-absolute top-0 start-0 m-3"
        />

        <Button
          variant="secondary"
          className={closeButton}
          onClick={handleCloseFormButton}
        >
          <span />
        </Button>

        <StaticImage
          src="./popup-image.jpg"
          alt="Person holding big question mark."
          width={350}
        />

        {feedbackSumbitted ? (
          <>
            <p className="fs-5">Thank you for your feedback!</p>
            <p className="fs-5">Click anywhere to close this window.</p>
            <Button
              variant="outline-secondary"
              className="rounded-pill mt-5"
              style={{borderWidth: '3px', padding: '0.75rem 4rem'}}
              onClick={resetForm}
            >
              Close window
            </Button>
          </>
        ) : (
          <>
            <p className="fs-5">
              Now you know you are eligible for the free Pneumococcal vaccine...
            </p>
            <h2 className={title}>
              Will you take part in the
              <br />
              National immunisation programme?
            </h2>
            <FeedbackForm
              formName={formName}
              setFeedbackSumbitted={setFeedbackSumbitted}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default Feedback
