import React from 'react'
import type {GatsbyBrowser} from 'gatsby'
import './src/assets/scss/main.scss'
import {Layout} from './src/components'

// IDs of hidden sections by default
const HIDDEN_SECTIONS = ['risks', 'diabetes', 'copd']

// Working on Gatsby v4. On version 5 is not working as expected.
// That's why we currenlty stay on v4
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: {location},
}) => {
  const {hash, pathname} = location
  if (hash && pathname === '/') {
    const id = HIDDEN_SECTIONS.find((sectionId) => hash.slice(1) === sectionId)
    if (!id) return true

    const element = document.getElementById(id)
    if (!element) return true

    element.classList.remove('d-none')

    // Return id of the element instead of true because.
    // It fixes the scroll to the section on page load and prevent Sonar error - "returning same value"
    return id
  }

  return true
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>
}
