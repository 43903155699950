import {Link} from 'gatsby'
import React, {useEffect, useRef, useState} from 'react'
import Row from 'react-bootstrap/Row'
import {HiCheckCircle, HiXCircle} from 'react-icons/hi'
import BigButton from '../../../components/BigButton'

import {Disease, Age, LongTermIssues} from '../types'
import {isEligible} from '../utils'

import {card, cardEligible, button} from './styles.module.scss'
import {Feedback, getFormProperties} from '../../../components/Feedback'
import {getCookie} from '../../../utils'

const DISEASES = {
  risks: {
    label: '65+ years & pneumococcal INFECTIONS',
    url: '#risks',
  },
  diabetes: {
    label: 'Diabetes & pneumococcal infections',
    url: '#diabetes',
  },
  copd: {
    label: 'COPD & pneumococcal infections',
    url: '#copd',
  },
}
interface Props {
  age: Age
  longTermIssues: LongTermIssues
  disease: Disease
}

const Card: React.FC<Props> = ({age, longTermIssues, disease}) => {
  const [eligible, setEligible] = useState<boolean>(false)
  const [showFeedback, setShowFeedback] = useState<boolean>(false)
  const cardRef = useRef(null)

  useEffect(() => {
    setEligible(isEligible(age, longTermIssues, disease))
  }, [age, longTermIssues, disease])

  /** Show the Feedback modal when a user is eligible
   *  and the Card components is 100% in the user viewport */
  useEffect(() => {
    const {surveyStatusCookieName} = getFormProperties()
    const feedbackSent = !!getCookie(surveyStatusCookieName)

    if (feedbackSent || !eligible || !cardRef.current) return undefined

    const options: IntersectionObserverInit = {
      threshold: 1.0, // Fire callback when element is 100% in viewport
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setShowFeedback(true)
          }, 2000)
        }
      })
    }, options)

    observer.observe(cardRef.current)

    return () => {
      observer.disconnect()
    }
  }, [eligible, setShowFeedback])

  const renderDiseaseButtonInfo = () => {
    if (
      age === Age.Over65 ||
      (age < Age.Over65 && !disease) ||
      disease === 'others' ||
      disease === 'risks' ||
      !disease
    )
      return null

    const {label, url} = DISEASES[disease]
    return (
      <Link to={url} className="text-uppercase">
        {label}
      </Link>
    )
  }

  return (
    <div
      className={`${card} pt-3 pb-5 px-5 ${eligible ? cardEligible : ''}`}
      ref={cardRef}
    >
      <Row className="justify-content-center text-center">
        {eligible ? (
          <HiCheckCircle size="150" title="Eligibility Icon" className="mb-3" />
        ) : (
          <HiXCircle size="150" title="Eligibility Icon" className="mb-3" />
        )}

        <h1 className="mb-3">
          {eligible ? 'YOU ARE ELIGIBLE' : 'YOU ARE UNLIKELY TO BE ELIGIBLE'}
        </h1>
        <h2>to get pneumococcal vaccination on the NHS</h2>
      </Row>
      <hr />

      {eligible ? (
        <Row className="justify-content-center text-center">
          <h2 className="mb-3">Speak to your doctor or practice nurse</h2>
          <p>
            Getting vaccinated against pneumococcal infections can help lower
            your risk of serious pneumococcal infections.
          </p>
          {renderDiseaseButtonInfo()}

          <Feedback setShowFeedback={setShowFeedback} show={showFeedback} />
        </Row>
      ) : (
        <Row className="justify-content-center">
          <p className="mb-4">
            The UK pneumococcal immunisation programme is provided on the NHS
            for people with certain long-term health conditions, and people aged
            65 and over. This is because the risk of serious pneumococcal
            infections is higher in these groups.
          </p>
          <BigButton href="/faq" className={button}>
            Visit our frequently asked questions
          </BigButton>
        </Row>
      )}
    </div>
  )
}

export default Card
