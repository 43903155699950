import React from 'react'
import {Container} from 'react-bootstrap'
import Links from './Links'
import SideEffects from './SideEffects'
import Contacts from './Contacts'
import './Footer.scss'

export interface Certificate {
  jobCode: string
  dateOfPrep: string
}
interface Props {
  certificate: Certificate
}

const Footer: React.FC<Props> = ({certificate}) => {
  return (
    <footer className="footer">
      <Container>
        <Links />
        <SideEffects />
        <Contacts {...certificate} />
      </Container>
    </footer>
  )
}

export default Footer
