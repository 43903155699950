import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import Title from '../Title'
import Text from '../Text'
import {
  externalLinkPopUp,
  content,
  button,
  titleContainer,
  bodyContainer,
} from './externallinkpopup.module.scss'

interface Props {
  show: boolean
  link: string
  handleClose: React.MouseEventHandler<HTMLButtonElement>
}

const ExternalLinkPopUp: React.FC<Props> = ({show, link, handleClose}) => {
  return (
    <Modal
      centered
      show={show}
      size="xl"
      className={externalLinkPopUp}
      onHide={handleClose}
    >
      <Modal.Body className={`${content} px-5`}>
        <div className={`mb-3 ${titleContainer}`}>
          <Title color="#324251" size="2.375rem">
            You are about to exit this MSD website.
          </Title>
        </div>
        <div className={`${bodyContainer}`}>
          <Text color="#324251" size="1rem">
            MSD makes no warranties or representations of any kind as to the
            accuracy, completeness, reliability or usefulness of any information
            contained in third party sites and shall have no liability for any
            loss or damage of any kind that may arise from your use of such
            content or information.
          </Text>

          <Text color="#324251" size="1rem">
            Inclusion of any third party link does not imply an endorsement or
            recommendation by MSD.
          </Text>
        </div>
        <div className="text-center mt-5">
          <Button
            variant="outline-primary"
            className={`${button} me-3 mb-3`}
            onClick={handleClose}
          >
            CLOSE
          </Button>
          <Button
            variant="outline-primary"
            className={`${button} ms-3 mb-3`}
            href={link}
            target="_blank"
            onClick={handleClose}
            rel="noreferrer"
          >
            CONTINUE
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ExternalLinkPopUp
