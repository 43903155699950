import React, {useState} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import BigButton from '../../components/BigButton'
import Card from './Card'
import SelectBox from './SelectBox'

import {showEligibleCard} from './utils'
import {EligibilityOption, Age, LongTermIssues} from './types'

import {wrapper} from './styles.module.scss'

const isActive = (buttonState: Age | LongTermIssues, value: 1 | 2) =>
  buttonState === value ? 'active' : ''

const EligibilityCalculator: React.FC = () => {
  const [age, setAge] = useState<Age>(0)
  const [longTermIssues, setLongTermIssues] = useState<LongTermIssues>(0)
  const [option, setOption] = useState<EligibilityOption>()

  return (
    <section id="eligibility-calculator" className={wrapper}>
      <Container className="my-5">
        <Row className="mb-5">
          <Col className="justify-content-center">
            <h1 className="text-lg-center">Are you eligible?</h1>
          </Col>
        </Row>
        <Row className="mb-4 justify-content-lg-center">
          <h2 className="mb-4 text-lg-center">
            How old are you or how old is the person you’re checking eligibility
            for?
          </h2>
          <Col xs="6" lg="4">
            <BigButton
              as="a"
              href=""
              onClick={() => setAge(1)}
              className={`${isActive(age, 1)} w-100`}
            >
              2-64 Years
            </BigButton>
          </Col>
          <Col xs="6" lg="4">
            <BigButton
              as="a"
              href=""
              onClick={() => setAge(2)}
              className={`${isActive(age, 2)} w-100`}
            >
              65 Years +
            </BigButton>
          </Col>
        </Row>

        {age === 1 && (
          <Row className="mb-5 justify-content-lg-center">
            <h2 className="mb-4 text-lg-center">
              Do you have a long-term health condition, such as COPD or
              diabetes?
            </h2>
            <Col xs="6" lg="4">
              <BigButton
                as="a"
                href=""
                onClick={() => setLongTermIssues(1)}
                className={`${isActive(longTermIssues, 1)} w-100`}
              >
                Yes
              </BigButton>
            </Col>
            <Col xs="6" lg="4">
              <BigButton
                as="a"
                href=""
                onClick={() => setLongTermIssues(2)}
                className={`${isActive(longTermIssues, 2)} w-100`}
              >
                No
              </BigButton>
            </Col>
          </Row>
        )}

        {age === 1 && longTermIssues === 1 && (
          <SelectBox {...{option, setOption}} />
        )}

        {showEligibleCard(age, longTermIssues, option) && (
          <Card {...{age, longTermIssues}} disease={option?.disease} />
        )}
      </Container>
    </section>
  )
}

export default EligibilityCalculator
