import React from 'react'
import {Container, Navbar, Nav} from 'react-bootstrap'
import {Link} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

import LogoMobile from './img/logo-mobile.inline.svg'
import LogoDesktop from './img/logo-desktop.inline.svg'

import './Header.scss'

interface MenuItem {
  id: number
  href: string
  text: string
}

const menuItems: Array<MenuItem> = [
  {
    id: 2,
    href: '/#risks',
    text: '65 and over & Pneumococcal infections',
  },
  {
    id: 3,
    href: '/#diabetes',
    text: 'Diabetes & Pneumococcal infections',
  },
  {
    id: 4,
    href: '/#copd',
    text: 'COPD & Pneumococcal infections',
  },
  {
    id: 5,
    href: '/faq',
    text: 'FAQs',
  },
]

const Header: React.FC = () => {
  return (
    <>
      <Container>
        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-lg-3 py-3">
          <StaticImage
            src="./img/logo-msd-color.png"
            alt="MSD logo"
            width={80}
          />
          <p className="header-info-text" style={{flex: '1 0'}}>
            This website has been developed and fully funded by the
            pharmaceutical company MSD.
            <br className="d-none d-lg-block" /> It is intended to provide
            general educational information and does not take the place of
            professional medical advice.
          </p>
        </div>
      </Container>
      <Navbar id="navbar" as="header" expand="lg" sticky="top" variant="dark">
        <Container fluid="xxl" as="nav" className="justify-content-between">
          <Navbar.Brand className="d-flex align-items-center">
            <Link to="/">
              <LogoMobile className="align-self-center d-lg-none" width={100} />
              <LogoDesktop
                className="align-self-center d-none d-lg-block"
                width={75}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              {menuItems.map(({id, href, text}) => (
                <Link key={id} to={href}>
                  <Nav.Link as="span" eventKey={href}>
                    {text}
                  </Nav.Link>
                </Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
