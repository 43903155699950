import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Select from 'react-select'

import {EligibilityOption} from '../types'
import _eligibilityOptions from './options.json'

import './styles.scss'

interface Props {
  option: EligibilityOption | undefined
  setOption: (option: EligibilityOption) => void
}

const eligibilityOptions = _eligibilityOptions as EligibilityOption[]

const SelectBox: React.FC<Props> = ({option, setOption}) => {
  const onChange = (optn: EligibilityOption | null) => {
    if (!optn) return
    setOption(optn)
  }

  return (
    <Row className="select-disease pb-5 justify-content-center">
      <Col lg="11">
        <Select
          classNamePrefix="react-select"
          placeholder="Select an option"
          isSearchable={false}
          options={eligibilityOptions}
          onChange={onChange}
          value={option}
        />
      </Col>
    </Row>
  )
}

export default SelectBox
