export const getCookie = (cookieName: string): string | null => {
  const cookieArr = document.cookie.split(';')
  for (const cookie of cookieArr) {
    const cookiePair = cookie.split('=')
    if (cookieName === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1])
    }
  }
  return null
}

export const setCookie = (name: string, value: string): void => {
  const cookie = `${name}=${encodeURIComponent(value)};path=/`
  document.cookie = cookie
}
