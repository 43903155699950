/* eslint-disable react/require-default-props */
import React from 'react'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

interface SEOProps {
  title?: string
  description?: string
  image?: string
  type?: string
  keywords?: string
  path?: string
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  image,
  type,
  keywords,
  path,
}) => {
  const {site} = useStaticQuery<Queries.SEOQuery>(query)

  if (!site?.siteMetadata) return null

  const {
    defaultTitle,
    defaultDescription,
    defaultKeywords,
    siteUrl,
    defaultImage,
    defaultType,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle || '',
    description: description || defaultDescription || '',
    keywords: keywords || defaultKeywords || '',
    url: `${siteUrl}/${path ?? ''}`,
    image: image || defaultImage || '',
    type: type || defaultType || '',
  }

  return (
    <Helmet title={seo.title}>
      <meta charSet="utf-8" />
      <link rel="canonical" href={seo.url} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.type && <meta property="og:type" content={seo.type} />}
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultKeywords: keywords
        siteUrl: siteUrl
        defaultImage: image
        defaultType: type
      }
    }
  }
`
